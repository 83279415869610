import { reactive } from "vue";

const plainOptions: any[] = [
    { label: "拥挤", value: "1" },
    { label: "开颌", value: "2" },
    { label: "龅牙", value: "3" },
    { label: "间隙", value: "4" },
    { label: "前牙反颌", value: "5" },
    { label: "深覆盖", value: "6" },
    { label: "II类1分类", value: "7" },
    { label: "后牙反颌", value: "8" },
    { label: "笑线不调", value: "9" },
    { label: "II类2分类", value: "10" },
    { label: "深覆颌", value: "11" },
    { label: "畸形牙", value: "12" },
    { label: "Ⅲ类", value: "13" },
    { label: "牙弓狭窄", value: "14" },
    { label: "其他", value: "15" },
];

const radioStyle = reactive({
    display: "flex",
    height: "30px",
    lineHeight: "30px",
});

const data = reactive({
    loading: false,
    disabled: false
});

export {
    plainOptions,
    radioStyle,
    data
}