import { ref } from "vue";
/**
 * 处方数据
 */
const option = ref<any[]>([
    {
        code: 1,
        name: "临床治疗情况",
        data: [],
        comment: null,
    },
    {
        code: 2,
        name: "需要治疗的牙弓",
        data: [],
        children: [
            {
                code: 1,
                data: null,
            },
            {
                code: 2,
                data: null,
            },
        ],
        comment: null,
    },
    {
        code: 3,
        name: "需要治疗的牙齿",
        data: 0,
        comment: null,
    },
    {
        code: 4,
        name: "牙齿移动限制",
        data: 0,
        comment: null,
        children: [],
    },
    {
        code: 5,
        name: "新的附件设计要求",
        data: 0,
        comment: null,
        children: [],
    },
    {
        code: 6,
        name: "矢状向（A-P）关系",
        data: null,
        comment: null,
        children: [
            {
                code: 1,
                name: "R",
                data: null,
            },
            {
                code: 2,
                name: "L",
                data: null,
            },
            {
                code: 3,
                name: "牙齿移动方法选择",
                data: [],
                children: [
                    {
                        code: 2,
                        name: "Ⅱ/Ⅲ类牵引",
                        data: null,
                    },
                    {
                        code: 3,
                        name: "远移磨牙",
                        data: null,
                    },
                ],
            },
        ],
    },
    {
        code: 7,
        name: "覆盖",
        data: 0,
        comment: null,
    },
    {
        code: 8,
        name: "覆合",
        data: 0,
        comment: null,
        children: [
            {
                code: 1,
                name: "纠正开合",
                data: null,
                children: [
                    {
                        code: 1,
                        name: "前牙内收",
                        data: null,
                    },
                    {
                        code: 2,
                        name: "前牙内收、磨牙压低",
                        data: null,
                    },
                ],
                comment: null,
            },
            {
                code: 2,
                name: "纠正深覆合",
                data: null,
                children: [
                    {
                        code: 1,
                        name: "前牙压低",
                        data: null,
                    },
                    {
                        code: 2,
                        name: "前牙压低、磨牙伸长",
                        data: null,
                    },
                ],
                comment: null,
            },
        ],
    },
    {
        code: 9,
        name: "中线",
        data: 0,
        comment: null,
        children: [
            {
                code: 3,
                name: "通过片切来改善",
                data: [],
                children: [
                    {
                        code: 1,
                        name: "上颌",
                        data: null,
                        comment: null,
                        children: [
                            {
                                code: 1,
                                name: "左",
                                data: null,
                                comment: null,
                            },
                            {
                                code: 2,
                                name: "右",
                                data: null,
                                comment: null,
                            }
                        ]
                    },
                    {
                        code: 2,
                        name: "下颌",
                        data: null,
                        comment: null,
                        children: [
                            {
                                code: 1,
                                name: "左",
                                data: null,
                                comment: null,
                            },
                            {
                                code: 2,
                                name: "右",
                                data: null,
                                comment: null,
                            }
                        ]
                    },
                ],
                comment: null,
            },
        ],
    },
    {
        code: 10,
        name: "后牙反合",
        data: null,
        comment: null,
        children: null,
    },
    {
        code: 11,
        name: "间隙与拥挤",
        data: null,
        comment: null,
        children: [
            {
                code: 1,
                name: "上颌",
                data: null,
                children: [
                    {
                        code: 1,
                        name: "扩弓",
                        data: null,
                    },
                    {
                        code: 2,
                        name: "唇倾",
                        data: null,
                    },
                    {
                        code: 3,
                        name: "IPR，前牙",
                        data: null,
                    },
                    {
                        code: 4,
                        name: "IPR，右上",
                        data: null,
                    },
                    {
                        code: 5,
                        name: "IPR，左上",
                        data: null,
                    },
                ],
            },
            {
                code: 2,
                name: "下颌",
                data: null,
                children: [
                    {
                        code: 1,
                        name: "扩弓",
                        data: null,
                    },
                    {
                        code: 2,
                        name: "唇倾",
                        data: null,
                    },
                    {
                        code: 3,
                        name: "IPR，前牙",
                        data: null,
                    },
                    {
                        code: 4,
                        name: "IPR，右上",
                        data: null,
                    },
                    {
                        code: 5,
                        name: "IPR，左上",
                        data: null,
                    },
                ],
            },
            {
                code: 3,
                name: "拔牙",
                data: 0,
                children: [],
            },
        ],
    },
    {
        code: 12,
        name: "病例过矫治",
        data: 0,
        comment: null,
        children: [],
    },
    {
        code: 13,
        name: "病例过矫治",
        data: 0,
        comment: null,
    },
    {
        code: 14,
        name: "特殊说明",
        data: "",
        comment: "",
    },
]);

/**
 * 牙齿列表切换
 * @param value 值
 * @param index 下标
 * @returns 
 */
const onChange = (value: any[], index: number) => {
    console.log(value, index, "选择牙齿");
    if (index == 10) {
        option.value[index].children[2].children = value;
        return;
    }
    option.value[index].children = value;
};

/**
 * 处方数据切换
 * @param index 
 * @param e 
 * @param i 
 */
const onCode2Change = (index: number, e: any, i?: number) => {
    console.log(index, e, i, "选择牙齿");
    if (index == 1) {
        if (!e.includes(0)) {
            option.value[index].children[0].data = null
        }
        if (!e.includes(1)) {
            option.value[index].children[1].data = null
        }
    }
    if (index == 3 || index == 4 || index == 9 || index == 11) {
        if (e.target.value != 1) {
            option.value[index].children = null
        }
    }
    if (index == 5) {
        if (!e.includes(1)) {
            option.value[index].children[2].children[0].data = null
        }
        if (!e.includes(2)) {
            option.value[index].children[2].children[1].data = null
        }
    }
    if (index == 7) {
        if (e.target.value != 0) {
            if (i != null) {
                option.value[index].children[i].children[0].data = null
            }
        }
        if (e.target.value != 1) {
            if (i != null) {
                option.value[index].children[i].children[1].data = null
            }
        }
    }
    if (index == 8) {

        switch (i) {
            case 1:
                if (e.target.value != 2) {
                    option.value[index].children[0].data = []
                }
                break;
            case 2:
                if (e.target.value == 0) {
                    option.value[index].children[0].children[0].children[1].comment = ''
                } else {
                    option.value[index].children[0].children[0].children[0].comment = ''
                }
                break;
            case 3:
                if (e.target.value == 0) {
                    option.value[index].children[0].children[1].children[1].comment = ''
                } else {
                    option.value[index].children[0].children[1].children[0].comment = ''
                }
                break;
            default:
                break;
        }
    }
    if (index == 10) {
        option.value[index].children[2].children = [];
    }
}

export {
    option,
    onChange,
    onCode2Change
}